@font-face {
    font-family: 'roboto_condensedbold_italic';
    src: url('/assets/fonts/RobotoCondensed-BoldItalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotobold_italic';
    src: url('/assets/fonts/Roboto-BoldItalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto_condenseditalic';
    src: url('/assets/fonts/RobotoCondensed-Italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoblack';
    src: url('/assets/fonts//assets/fonts/Roboto-Black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotothin';
    src: url('/assets/fonts/Roboto-Thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotomedium_italic';
    src: url('/assets/fonts/Roboto-MediumItalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto_condensedregular';
    src: url('/assets/fonts/RobotoCondensed-Regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotolight_italic';
    src: url('/assets/fonts/Roboto-LightItalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoregular';
    src: url('/assets/fonts/Roboto-Regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotomedium';
    src: url('/assets/fonts/Roboto-Medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotolight';
    src: url('/assets/fonts/Roboto-Light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto_condensedlight';
    src: url('/assets/fonts/RobotoCondensed-Light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoitalic';
    src: url('/assets/fonts/Roboto-Italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotobold';
    src: url('/assets/fonts/Roboto-Bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotothin_italic';
    src: url('/assets/fonts/Roboto-ThinItalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto_condensedlight_italic';
    src: url('/assets/fonts/RobotoCondensed-LightItalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto_condensedbold';
    src: url('/assets/fonts/RobotoCondensed-Bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoblack_italic';
    src: url('/assets/fonts/Roboto-BlackItalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
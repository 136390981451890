
// variables


$text-color: #000000;
$header-color: #333333;
$link-color: #212121;
$footer-color: #555555;
$hoover-color: #555555;

$default-font-family: 'robotoregular', Arial, Helvetica, sans-serif;
$bold-font-family: 'robotobold', Arial, Helvetica, sans-serif;
$italic-font-family: 'robotoitalic', Arial, Helvetica, sans-serif;

$default-font-size: 1.2em;
$footer-font-size: 1em;

body {
    font-family: $default-font-family;
    color: $text-color;
    font-size: $default-font-size;
    text-align: justify;
}

h1, h2, h3, h4, h5, h6 {
    color: $header-color;
}

a {
    color: $link-color;
    text-decoration: underline;

    &:hover {
        color: $hoover-color;
    }
}

img.service {
    max-height: 250px;

}

.spacer {
    margin-top: 80px;
}

.bold {
    font-family: $bold-font-family;
}

footer {
    font-size: $footer-font-size;
    color: $footer-color;

    a {
        color: $footer-color;
        text-decoration: none;
    }
}

// https://stackoverflow.com/questions/46162385/bootstrap-4-intl-tel-input-workaround
.iti.iti--allow-dropdown { width: 100% }

// show bigger picture on mobile
.skill-image {
    width: 80%;
}
@media (max-width: 767px) {
    .skill-image {
        width: 100%;
    }
}
